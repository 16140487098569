const videoModal = () => {
  const modal = document.getElementById('modal-video');
  const triggers = document.querySelectorAll('.js-video-modal-trigger');
  if (!modal || !triggers.length) return;

  // System variables
  let currentHref = '';
  let videoInstance = null;

  // === Elements
  const modalVideoWrap = modal.querySelector('.modal__body');
  const modalClose = modal.querySelector('.js-modal-close');

  // === Methods
  const open = (type, href) => {
    if (currentHref === href) {
      modal.classList.add('show');
      videoInstance && videoInstance.play();
      return;
    }

    modalVideoWrap.innerHTML = '';
    videoInstance && videoInstance.dispose();
    currentHref = href;

    if (type === 'local') {
      modalVideoWrap.insertAdjacentHTML('afterbegin', `<video-js id='vid1' controls='true'><source src='${href}'></video-js>`);
    }

    if (type === 'youtube') {
      modalVideoWrap.insertAdjacentHTML('afterbegin', `<video id='vid1' class='video-js vjs-default-skin' controls autoplay data-setup='{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "${href}"}] }'></video>`);
    }

    videoInstance = videojs('vid1');
    videoInstance && videoInstance.play();
    modal.classList.add('show');
  };

  const close = () => {
    videoInstance && videoInstance.pause();
    modal.classList.remove('show');
  };

  const clickHandle = ({ currentTarget: trigger }) => {
    const videoHref = trigger.dataset?.localSrc || trigger.dataset?.youtubeHref || '';

    if (trigger.dataset['localSrc']) {
      open('local', videoHref);
    } else if (trigger.dataset['youtubeHref']) {
      open('youtube', videoHref);
    }
  };


  // === Init
  triggers.forEach(trigger => {
    trigger.addEventListener('click', clickHandle);
  });

  modal.addEventListener('click', ({ target, currentTarget }) => {
    if (target === currentTarget) {
      close();
    }
  });

  modalClose.addEventListener('click', () => {
    close();
  });

  return { videoModalOpen: open, videoModalClose: close };
};


const fadeIn = (el, delay, callback, display = 'block') => {
  el.style.opacity = 0;
  el.style.display = display;
  el.style.transition = `opacity ${delay}ms`;
  setTimeout(() => {
    el.style.opacity = 1;

    callback && callback(el);
  }, delay);
};

const fadeOut = (el, delay, callback) => {
  el.style.opacity = 1;
  el.style.transition = `opacity ${delay}ms`;
  el.style.opacity = 0;

  setTimeout(() => {
    el.style.display = 'none';

    callback && callback(el);
  }, delay);
};

/* SLIDE UP */
const slideUp = (target, duration = 500, callback) => {
  target.style.transitionProperty = `height, margin, padding`;
  target.style.transitionDuration = duration + `ms`;
  target.style.boxSizing = `border-box`;
  target.style.height = target.offsetHeight + `px`;
  target.offsetHeight;
  target.style.overflow = `hidden`;
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = `none`;
    target.style.removeProperty(`height`);
    target.style.removeProperty(`padding-top`);
    target.style.removeProperty(`padding-bottom`);
    target.style.removeProperty(`margin-top`);
    target.style.removeProperty(`margin-bottom`);
    target.style.removeProperty(`overflow`);
    target.style.removeProperty(`transition-duration`);
    target.style.removeProperty(`transition-property`);

    callback && callback(target);
    // alert("!")
  }, duration);
};

/* SLIDE DOWN */
const slideDown = (target, duration = 500, callback) => {
  target.style.removeProperty(`display`);
  let display = window.getComputedStyle(target).display;
  if (display === `none`) {
    display = `block`;
  }
  target.style.display = display;
  const height = target.offsetHeight;
  target.style.overflow = `hidden`;
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = `border-box`;
  target.style.transitionProperty = `height, margin, padding`;
  target.style.transitionDuration = duration + `ms`;
  target.style.height = height + `px`;
  target.style.removeProperty(`padding-top`);
  target.style.removeProperty(`padding-bottom`);
  target.style.removeProperty(`margin-top`);
  target.style.removeProperty(`margin-bottom`);
  window.setTimeout(() => {
    target.style.removeProperty(`height`);
    target.style.removeProperty(`overflow`);
    target.style.removeProperty(`transition-duration`);
    target.style.removeProperty(`transition-property`);

    callback && callback(target);
  }, duration);
};

/* TOOGGLE */
const slideToggle = (target, duration = 500, callback) => {
  if (window.getComputedStyle(target).display === `none`) {
    return slideDown(target, duration, callback);
  } else {
    return slideUp(target, duration, callback);
  }
};

const checkCookies = () => {
  const cookiesContainer = document.querySelector('.js-cookies');
  if (!cookiesContainer) return;

  const getCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  const cookiesSubmit = cookiesContainer.querySelector('.js-cookies-submit');
  const cookiesClose = cookiesContainer.querySelector('.js-cookies-close');
  const cookies_submitted = getCookie('cookies_submitted');

  if (!cookies_submitted) {
    cookiesContainer.classList.add('show');
    cookiesSubmit.addEventListener('click', () => {
      setCookie('cookies_submitted', true, 0);
      cookiesContainer.classList.remove('show');
    });
    cookiesClose.addEventListener('click', () => {
      cookiesContainer.classList.remove('show');
    });
  }
};

// Lock scrollbar
const lockScrollBar = (toLock = true) => {
  const widthScrollBar = window.innerWidth - document.body.getBoundingClientRect().width;
  const header = document.querySelector('.header');
  const scrollUp = document.querySelector('.scrollup');

  if (toLock) {
    if (widthScrollBar > 0) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${widthScrollBar}px`;
      if (header) {
        header.style.paddingRight = `${widthScrollBar}px`;
      }
      if (scrollUp) {
        scrollUp.style.marginRight = `${widthScrollBar}px`;
      }
    }
  } else {
    document.body.style.overflow = 'initial';
    document.body.style.paddingRight = '0px';
    if (header) {
      header.style.paddingRight = '0px';
    }
    if (scrollUp) {
      scrollUp.style.marginRight = 0;
    }
  }
};

window.addEventListener('load', () => {
  const headerRef = document.querySelector('.header');

  document.documentElement.style.setProperty('--header-height', `${headerRef?.getBoundingClientRect().height || 100}px`);

  checkCookies();

  // AOS init ====================================
  AOS.init({
    duration: 750,
    once: true,
    easing: 'ease'
  });
  // AOS init end ====================================

  // Dropdown
  select();
  // Dropdown end

  // Enable disabled elements
  const disabledElements = document.querySelectorAll('.important_none');

  disabledElements.length &&
  disabledElements.forEach(($element) => {
    $element.classList.remove('important_none');
  });
  // Enable disabled elements end

  // Header ====================================
  let windowScrollPosition = 0;

  const hideHeader = () => {
    const { pageYOffset } = window;

    pageYOffset > windowScrollPosition ? headerRef.classList.add('hide') : headerRef.classList.remove('hide');

    windowScrollPosition = pageYOffset;
  };

  // Header - burger
  const burgerBtnRef = document.querySelector('.burger-btn');
  const navbarRef = document.querySelector('.nav');

  const burgerClose = () => {
    if (burgerBtnRef && navbarRef) {
      burgerBtnRef.classList.remove('active');
      navbarRef.classList.remove('show');
      document.body.classList.remove('body-lock');
    }
  };

  const burgerHandler = () => {
    if (navbarRef && burgerBtnRef) {
      burgerBtnRef.onclick = () => {
        burgerBtnRef.classList.toggle('active');
        navbarRef.classList.toggle('show');
        document.body.classList.toggle('body-lock');
      };
      navbarRef.onclick = (e) => {
        if (e.target === e.currentTarget) {
          burgerClose();
        }
      };
    }
  };

  burgerBtnRef && navbarRef && burgerHandler();
  burgerBtnRef &&
  navbarRef &&
  window.addEventListener('resize', () => {
    if (window.innerWidth >= 1200) burgerClose();
  });
  // Header - burger
  // Header - dropdown
  const headerDropdownTrigger = document.querySelector('.js-nav-dropdown-trigger');
  const headerDropdownList = document.querySelector('.js-nav-dropdown-list');

  if (headerDropdownTrigger && headerDropdownList) {
    headerDropdownTrigger.addEventListener('click', (event) => {
      headerDropdownTrigger.classList.toggle('active');
      headerDropdownList.classList.toggle('show');
    });

    document.addEventListener('click', (event) => {
      let targetEl = event.target;
      do {
        if (targetEl === headerDropdownTrigger || targetEl === headerDropdownList) return;
        targetEl = targetEl.parentNode;
      } while (targetEl);
      headerDropdownTrigger.classList.remove('active');
      headerDropdownList.classList.remove('show');
    });
  }
  // Header - dropdown end
  // Header - lang dropdown
  const headerLangDropdownTrigger = document.querySelector('.js-lang-dropdown-trigger');
  const headerLangDropdownList = document.querySelector('.js-lang-dropdown-list');

  if (headerLangDropdownTrigger && headerLangDropdownList) {
    headerLangDropdownTrigger.addEventListener('click', (event) => {
      headerLangDropdownTrigger.classList.toggle('active');
      headerLangDropdownList.classList.toggle('show');
    });

    document.addEventListener('click', (event) => {
      let targetEl = event.target;
      do {
        if (targetEl === headerLangDropdownTrigger || targetEl === headerLangDropdownList) return;
        targetEl = targetEl.parentNode;
      } while (targetEl);
      headerLangDropdownTrigger.classList.remove('active');
      headerLangDropdownList.classList.remove('show');
    });
  }
  // Header - lang dropdown end
  // Header end ================================

  // Home page ====================================
  // Home page - banner
  new Swiper('.main-banner__slider', {
    speed: 1000,
    loop: true,
    slidesPerView: 1,
    // autoplay: {
    //   delay: 5000,
    // },
    pagination: {
      el: '.main-banner__slider .pagination-primary',
      clickable: true
    }
  });
  // Home page - banner end

  // Home page - clients
  new Swiper('.clients__slider', {
    speed: 400,
    spaceBetween: 30,
    slidesPerView: 2,
    breakpoints: {
      540: {
        slidesPerView: 3
      },
      768: {
        spaceBetween: 100,
        slidesPerView: 4
      },
      992: {
        spaceBetween: 130,
        slidesPerView: 4
      },
      1200: {
        spaceBetween: 183,
        slidesPerView: 5
      }
    },
    pagination: {
      el: '.clients__slider .pagination-primary',
      clickable: true
    }
  });
  // Home page - clients end
  // Home page - how it works animation
  const howItWorks = document.querySelectorAll('.js-how-it-works');
  if (howItWorks.length) {
    howItWorks.forEach((howItWork) => {
      const showMoreBtn = howItWork.querySelector('.js-show-more');

      showMoreBtn &&
      showMoreBtn.addEventListener('click', () => {
        howItWork.classList.remove('hide-mobile');
        showMoreBtn.remove();
      });

      // Animation
      let currentCircle = 0;
      const howItWorksCircles = howItWork.querySelectorAll('.circle');

      setInterval(() => {
        const item = howItWorksCircles[currentCircle];

        item.classList.add('hide');

        setTimeout(() => {
          item.classList.remove('hide');
        }, 125);

        currentCircle < howItWorksCircles.length - 1 ? currentCircle++ : (currentCircle = 0);
      }, 250);
    });
  }
  // Home page - how it works animation end

  // Home page - feedback-card slider
  new Swiper('.feedback__slider', {
    speed: 400,
    spaceBetween: 30,
    slidesPerView: 1,
    breakpoints: {
      680: {
        slidesPerView: 2
      },
      1200: {
        spaceBetween: 81,
        slidesPerView: 3
      }
    },
    pagination: {
      el: '.feedback__slider .pagination-primary',
      clickable: true
    }
  });
  // Home page - feedback-card slider end
  // Home page end ====================================

  // Single article page ====================================
  const articleContainer = document.querySelector('.article-content');
  // Single article - create gallery
  if (articleContainer) {
    // New events
    const eventClick = new Event('click');

    // Get article media files
    const articleImages = articleContainer.querySelectorAll('img');
    const articleVideos = articleContainer.querySelectorAll('video');

    // Create gallery container
    const galleryContainer = createEl('div');
    galleryContainer.classList.add('lightgallery');

    articleImages.forEach((img, i) => {
      // Change current img
      img.dataset.imgIndex = i;
      img.dataset.galleryItem = '';
      const { src, alt } = img;

      // Create img tag
      const newImg = createEl('img');
      newImg.src = src;
      newImg.alt = alt;

      // Create gallery item tag
      const galleryItem = createEl('a');
      galleryItem.dataset.imgIndex = i;
      galleryItem.href = src;
      galleryItem.setAttribute('aria-label', 'Open image gallery');
      galleryItem.appendChild(newImg);

      galleryContainer.appendChild(galleryItem);
    });

    // Init gallery
    articleContainer.appendChild(galleryContainer);
    const galleries = $('.lightgallery').lightGallery({
      download: false,
      share: false,
      speed: 500,
      loop: false,
      mobileSettings: [
        {
          controls: false,
          showCloseIcon: true,
          download: false
        }
      ]
    });

    // Render pagination
    if (galleries.length) {
      let slideIndex = 0;
      let currentOuterLg = null;
      let currentInnerLg = null;

      const lockScroll = () => {
        const { body } = document;
        if (Math.round(body.getBoundingClientRect().height) > window.innerHeight) {
          const scrollbarWidth = calcScroll();
          body.style.overflow = 'hidden';
          body.style.paddingRight = `${scrollbarWidth}px`;
          headerRef.style.paddingRight = scrollbarWidth + 'px';
          scrollUpBtn.parentElement.style.marginRight = scrollbarWidth + 'px';
        }
      };

      const unlockScroll = () => {
        const { body } = document;
        if (Math.round(body.getBoundingClientRect().height) > window.innerHeight) {
          body.style.overflow = 'initial';
          body.style.paddingRight = '0px';
          headerRef.style.paddingRight = '0px';
          scrollUpBtn.parentElement.style.marginRight = '0px';
        }
      };

      const createPaginationMarkup = (slidesCollection) => {
        const paginationWrap = createEl('div');
        paginationWrap.classList.add('pagination-primary');
        for (let i = 0; i < slidesCollection.length; i++) {
          const paginationBullet = createEl('span');
          paginationBullet.classList.add('swiper-pagination-bullet');
          if (slidesCollection[i].classList.contains('lg-current')) {
            slideIndex = i;
            paginationBullet.classList.add('swiper-pagination-bullet-active');
          }
          paginationWrap.appendChild(paginationBullet);
        }
        return paginationWrap;
      };

      const galleryPaginationHandler = () => {
        currentOuterLg = document.querySelector('.lg-outer');
        currentInnerLg = currentOuterLg.querySelector('.lg-inner');
        const paginationMarkup = createPaginationMarkup(currentInnerLg.children);
        currentOuterLg.appendChild(paginationMarkup);
      };

      const changeCurrentPagination = (slideIndex) => {
        const pagination = currentOuterLg.querySelector('.pagination-primary');
        for (let i = 0; i < pagination.children.length; i++) {
          pagination.children[i].classList.remove('swiper-pagination-bullet-active');
        }
        pagination.children[slideIndex].classList.add('swiper-pagination-bullet-active');
      };

      const prevSlide = () => {
        slideIndex > 0 ? (slideIndex -= 1) : (slideIndex = currentInnerLg.children.length - 1);
        changeCurrentPagination(slideIndex);
      };

      const nextSlide = () => {
        slideIndex < currentInnerLg.children.length - 1 ? (slideIndex += 1) : (slideIndex = 0);
        changeCurrentPagination(slideIndex);
      };

      for (let i = 0; i < galleries.length; i++) {
        const gallery = galleries[i];
        gallery.onBeforeOpen = lockScroll;
        gallery.onCloseAfter = unlockScroll;
        gallery.onAfterOpen = galleryPaginationHandler;
        gallery.onBeforePrevSlide = prevSlide;
        gallery.onBeforeNextSlide = nextSlide;
      }
    }

    // Add listeners
    articleImages.forEach((img) => {
      img.addEventListener('click', (e) => {
        const target = e.target;
        const galleryItems = galleryContainer.querySelectorAll('a');
        if (galleryContainer) {
          galleryItems.forEach((item) => {
            if (item.dataset.imgIndex === target.dataset.imgIndex) {
              item.dispatchEvent(eventClick);
            }
          });
        }
      });
    });
  }
  // Single article - create gallery end

  // Single article - video
  if (articleContainer) {
    const videos = articleContainer.querySelectorAll('video');

    videos.forEach((video) => {
      const videoContainer = video.parentNode;
      let timerId = null;

      video.addEventListener('progress', (event) => {
        videoContainer.classList.add('no-play-btn');
        videoContainer.classList.add('paly-hide');
      });

      video.addEventListener('click', () => (video.controls = true));
      video.addEventListener('pause', () => {
        timerId = setTimeout(() => {
          videoContainer.classList.remove('paly-hide');
        }, 200);
      });
      video.addEventListener('play', () => {
        clearTimeout(timerId);
        videoContainer.classList.add('paly-hide');
      });
      video.addEventListener('ended', () => {
        videoContainer.classList.remove('paly-hide');
      });
    });

    // videos.forEach((video, i) => {
    //   const { children, poster } = video;

    //   // Change video
    //   video.dataset.modalTarget = `video${i}`;
    //   video.dataset.videoTrigger = '';

    //   // Create backdrop
    //   const backdrop = createEl('div');
    //   backdrop.classList.add('backdrop-video');
    //   backdrop.id = `video${i}`;

    //   // Create new video
    //   const newVideo = createEl('video');
    //   newVideo.controls = 'controls';
    //   newVideo.poster = poster;
    //   newVideo.appendChild(...children);

    //   // Create title holder
    //   const titleHolder = createEl('h2');
    //   titleHolder.innerHTML = video.dataset.titleHolder;

    //   // Create dialog
    //   const dialog = createEl('div');
    //   dialog.classList.add('backdrop-video__dialog');

    //   // Create play button
    //   const playBnt = createEl('button');
    //   playBnt.setAttribute('aria-label', 'video-play');
    //   playBnt.classList.add('video-play');

    //   // Create close button
    //   const closeBnt = createEl('button');
    //   closeBnt.setAttribute('aria-label', 'close button');
    //   closeBnt.classList.add('backdrop-video__close');

    //   // Render elements
    //   dialog.appendChild(newVideo);
    //   dialog.appendChild(titleHolder);
    //   dialog.appendChild(playBnt);
    //   dialog.appendChild(closeBnt);
    //   backdrop.appendChild(dialog);
    //   document.body.appendChild(backdrop);

    //   console.log(backdrop);

    //   // Listeners
    //   playBnt.addEventListener('click', () => {
    //     playBnt.classList.add('hide');
    //     newVideo.play();
    //   });

    //   // Video events
    //   newVideo.onpause = () => playBnt.classList.remove('hide');
    //   newVideo.onplay = () => playBnt.classList.add('hide');
    //   newVideo.onended = () => playBnt.classList.remove('hide');
    // });

    // // Init modals
    // new Modal({
    //   modalSelector: '.backdrop-video',
    //   triggerSelector: 'video[data-video-trigger]',
    //   closeSelector: '.backdrop-video__close',
    //   on: {
    //     modalOpen: (e) => {
    //       headerRef.style.paddingRight = e.scrollbarWidht + 'px';
    //       scrollUpBtn.parentElement.style.marginRight = e.scrollbarWidht + 'px';
    //     },
    //     modalClose: (e, modal) => {
    //       headerRef.style.paddingRight = '0px';
    //       scrollUpBtn.parentElement.style.marginRight = '0px';
    //       modal.querySelector('video').pause();
    //     }
    //   }
    // });
  }
  // Single article - video end
  // Single article page end ====================================
  // Call center page ====================================
  const benefitsSliderHandle = (instanse) => {
    const { slides } = instanse;
    const checkWinWidth = window.innerWidth >= 800;
    if (checkWinWidth && slides.length <= 3) {
      instanse.slideTo(1);
      instanse.params.centeredSlides = true;
      instanse.update();
    } else if (checkWinWidth && slides.length > 3) {
      instanse.slideTo(0);
      instanse.params.centeredSlides = false;
      instanse.update();
    }
  };

  const s = new Swiper('.benefits-block-solid__slider', {
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    allowTouchMove: true,
    breakpoints: {
      500: {
        slidesPerView: 2
      },
      800: {
        slidesPerView: 3
      },
      992: {
        spaceBetween: 0
      },
      1050: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      1051: {
        slidesPerView: 4,
        allowTouchMove: false,
        spaceBetween: 0
      }
    },
    pagination: {
      el: '.clients__slider .pagination-primary',
      clickable: true
    },
    on: {
      init: benefitsSliderHandle,
      resize: (instanse) => {
        if (window.innerWidth <= 1050) {
          instanse.params.allowTouchMove = true;
          instanse.slideTo(0);
        } else if (window.innerWidth <= 1050 && slides.length < 3) {
          instanse.slideTo(1);
        }
        benefitsSliderHandle(instanse);
      }
    }
  });

  // Call center page end ====================================
  // Prices page ====================================
  // Prices page - accordion
  const pricesPage = document.querySelector('.prices-page');
  if (pricesPage) {
    const priceCard = pricesPage.querySelectorAll('.js-prices-card');
    const delay = 500;

    const lockEl = (el, delay) => {
      el.style.pointerEvents = 'none';
      setTimeout(() => {
        el.style.pointerEvents = 'all';
      }, delay);
    };

    const initSlideToggler = () => {
      priceCard.forEach((card) => {
        const header = card.querySelector('.js-prices-card-header');
        const body = card.querySelector('.js-prices-card-body');
        header.onclick = () => {
          slideToggle(body, delay);
          header.classList.toggle('active');
          lockEl(header, delay);
        };
      });
    };
    const destroySlideToggler = () => {
      priceCard.forEach((card) => {
        const header = card.querySelector('.js-prices-card-header');
        const body = card.querySelector('.js-prices-card-body');
        header.onclick = null;
        body.removeAttribute('style');
      });
    };

    priceCard.length && window.innerWidth <= 1155 && initSlideToggler();
    priceCard.length &&
    window.addEventListener('resize', () => (window.innerWidth >= 1155 ? destroySlideToggler() : initSlideToggler()));
  }

  // Prices page - accordion end
  // Prices page end ====================================

  // Common ====================================
  // Common - simplebar
  const simpleBarEls = document.querySelectorAll('.js-simplebar');

  simpleBarEls.forEach((el) => new SimpleBar(el));
  // Common - simplebar end
  // Common - header search here
  new Modal({
    modalSelector: '.js-search-modal',
    triggerSelector: '.js-search-modal-trigger',
    closeSelector: '.js-modal-close',
    on: {
      modalOpen: (e, modal) => {
        headerRef.style.paddingRight = e.scrollbarWidht + 'px';
        scrollUpBtn.parentElement.style.marginRight = e.scrollbarWidht + 'px';

        if (window.innerWidth >= 992) {
          modal.style.paddingRight = e.scrollbarWidht + 'px';
        }
      },
      modalClose: (e, modal) => {
        headerRef.style.paddingRight = '0px';
        scrollUpBtn.parentElement.style.marginRight = '0px';
        if (window.innerWidth >= 992) {
          modal.style.paddingRight = '0px';
        }
        modal.querySelector('form').reset();
      }
    }
  });

  // Primary modal
  const header = document.querySelector('.header');
  const scrollup = document.querySelector('.scrollup');
  new Modal({
    modalSelector: '[data-modal-primary]',
    triggerSelector: '[data-modal-primary-trigger]',
    closeSelector: '[data-modal-primary-close]',
    on: {
      modalOpen: ({ scrollbarWidht }) => {
        burgerClose();
        header.style.paddingRight = `${scrollbarWidht}px`;
        scrollup.style.marginRight = `${scrollbarWidht}px`;
      },
      modalClose: (instance, currentModal) => {
        const forms = currentModal.querySelectorAll('form');

        forms.forEach((form) => {
          const invalidInputs = form.querySelectorAll('[data-invalid-target]');

          invalidInputs.forEach((input) => input.classList.remove('invalid'));
          form.reset();
        });

        header.style.paddingRight = 0;
        scrollup.style.marginRight = 0;
      }
    }
  });
  // Primary modal end
  // Common - header search end

  // Common - product card slider
  new Swiper('.products__slider', {
    loop: true,
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 10,
    autoplay: {
      delay: 5000
    },
    breakpoints: {
      600: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 1
      }
    }
  });
  // Common - product card slider end

  // Common - scroll handler
  const scrollUpBtn = document.querySelector('.scrollup-btn');

  scrollUpBtn &&
  window.addEventListener('scroll', () => {
    window.pageYOffset > 10 ? scrollUpBtn.classList.add('active') : scrollUpBtn.classList.remove('active', 'hide');

    hideHeader();
  });
  // Common - scroll handler end

  // Common - social block
  const socialBlock = document.querySelector('.socials-block');
  const socialTrigger = socialBlock && socialBlock.querySelector('.socials-block__trigger');
  const socialList = socialBlock && socialBlock.querySelector('.socials-block__list');
  const socialTimeout = 300;
  const lockSocialTrigger = (removeActiveClass) => {
    socialTrigger.style.pointerEvents = 'none';
    setTimeout(() => {
      socialTrigger.style.pointerEvents = 'all';
      if (removeActiveClass) {
        socialTrigger.classList.remove('active');
      }
    }, socialTimeout);
  };

  socialTrigger &&
  socialList &&
  socialTrigger.addEventListener('click', (e) => {
    slideToggle(socialList, socialTimeout);
    if (socialTrigger.classList.contains('active')) {
      lockSocialTrigger(true);
    } else {
      lockSocialTrigger();
      socialTrigger.classList.add('active');
    }
  });

  // Common - social block end
  // Common - filter btns slider
  new Swiper('.filter__slider', {
    speed: 400,
    spaceBetween: 20,
    allowTouchMove: true,
    slidesPerView: 'auto'
  });
  // Common - filter btns slider end
  // Common - smooth scroll
  const anchorBtns = document.querySelectorAll('a[href^=\'#\']');
  anchorBtns.forEach((anchorBtn) => anchorBtn.addEventListener('click', anchorScrollHandler));

  function anchorScrollHandler(e) {
    const targetId = e.currentTarget.getAttribute('href').replace(/#/gm, '');
    const targetSection = targetId ? document.querySelector(`#${targetId}`) : null;
    if (targetSection) {
      e.preventDefault();
      let headerOffset = headerRef.style.position === 'fixed' ? headerRef.offsetHeight : 0;
      const targetSectionRect = targetSection.getBoundingClientRect();
      window.scrollTo({
        top: targetSectionRect.top - headerOffset,
        behavior: 'smooth'
      });
    }
  }

  // Common - smooth scroll end
  // Common - Accordion
  let footerTriggers = document.querySelectorAll('.accordion__toggler');
  let blocksFooter = document.querySelectorAll('.accordion__list');

  if (footerTriggers && blocksFooter) {
    let closeBlocks = function closeBlocks(except) {
      blocksFooter.forEach(function(item) {
        if (item != except) {
          slideUp(item, 300);
          let parent = item.closest('.accordion__item');
          parent.classList.remove('active');
        }
      });
    };

    let parents = document.querySelectorAll('.accordion__item');
    let last_click = 0;
    footerTriggers.forEach(function(item) {
      let parent = item.closest('.accordion__item');
      let block = parent.querySelector('.accordion__list');
      item.addEventListener('click', function(e) {
        let date = Date.now();

        if (date - last_click > 400) {
          let show = !parent.classList.contains('active');
          let promise = new Promise(function(resolve, reject) {
            parents.forEach(function(item) {
              if ($(item).is('.active')) {
                slideUp($(item).find('.accordion__list')[0], 300);
                item.classList.remove('active');
              }
            });
            resolve(true);
          });
          promise.then(function(resp) {
            if (show) {
              slideDown(block, 300);
              $(parent).addClass('active');
            }
          });
          last_click = date;
        }
      });
    });
  }

  let footerColumns = document.querySelectorAll('.accordion__item');
  window.addEventListener('resize', function() {
    if (window.innerWidth > 1024 && footerColumns) {
      footerColumns.forEach((column) => {
        let block = column.querySelector('.accordion__list');
        if (block.hasAttribute('style') || column.classList.contains('active')) {
          block.removeAttribute('style');
          column.classList.remove('active');
        }
      });
    }
  });
  // Common - Accordion end
  // Common - Validation
  const numbersInputs = document.querySelectorAll('input[data-validation-type="phone"]');

  numbersInputs.length &&
  numbersInputs.forEach((input) =>
    input.addEventListener('input', () => (input.value = input.value.replace(/[^0-9() +-]/g, '')))
  );
  const forms = document.querySelectorAll('form[data-validation]');

  if (forms.length) {
    forms.forEach((form) => {
      const passwordWrap = form.querySelectorAll('[data-password-wrap]');

      passwordWrap.forEach((wrap) => {
        const togglePassword = wrap.querySelector('[data-toggle-password]');
        const targetPassword = wrap.querySelector('[data-target-password]');

        togglePassword.addEventListener('click', () => {
          targetPassword.type === 'password' ? (targetPassword.type = 'text') : (targetPassword.type = 'password');
        });
      });

      form.addEventListener('input', (e) => formHandler(e.target));
      form.addEventListener('submit', (e) => {
        e.preventDefault();

        let validStatus = true;
        const inputs = e.currentTarget.querySelectorAll('[data-need-validation="true"]');
        const parent = e.target.closest('.modal');

        inputs.forEach((el) => {
          if (!formHandler(el)) validStatus = false;
        });
        // if (!validStatus) e.preventDefault();

        if (validStatus) {
          let jsonData = fetch(e.target.getAttribute('action'), {
            headers: {
              'Accept': 'application/json',
              'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            method: e.target.getAttribute('method'),
            body: new FormData(e.target)
          }).then(response => {
            if (!response.ok) return Promise.reject(response);

            if (e.target.dataset.formType === 'forgot-password') {
              const finallyModal = document.querySelector('#success-forgot-password');
              parent && parent.classList.remove('show');
              finallyModal && finallyModal.classList.add('show');
            }

            if (e.target.dataset.formType === 'conection') {
              const finallyModal = document.querySelector('#success-book-demo');
              parent && parent.closest('.modal').classList.remove('show');
              finallyModal && finallyModal.classList.add('show');
            }

            e.target.reset();
            return response.json();
          }).catch(error => {
            const finallyModal = document.querySelector('#error-sending');
            parent && parent.closest('.modal').classList.remove('show');
            finallyModal && finallyModal.classList.add('show');
            console.error(error);
          });

          jsonData.then((data) => {
            if (typeof data.form_type != 'undefined') {
              window.dataLayer.push({
                event: 'formSuccess',
                formName: data.form_type.name,
                'email': data.form_type.email || '',
                'phone': data.form_type.phone || ''
              });
            }
          });
        }
      });
    });
  }
  // Common - Validation end

  //masks
  const inputCountry = document.querySelectorAll('[data-intl]');
  if (inputCountry) {
    inputCountry.forEach((inputState) => {
      let iti = intlTelInput(inputState, {
        onlyCountries: [
          'us',
          'gb',
          'ua',
          'at',
          'bg',
          'cz',
          'dk',
          'ee',
          'fi',
          'fr',
          'de',
          'is',
          'ie',
          'it',
          'lv',
          'nl',
          'no',
          'pl',
          'sk',
          'si',
          'es',
          'se',
          'ch'
        ],
        initialCountry: 'UA',
        autoHideDialCode: false,
        preferredCountries: ['gb', 'us', 'ua'],
        nationalMode: false
      });
    });
  }

  // Ics instructions slider
  const IcsInstructionsSlider = document.querySelector('.ics-instructions__slider');
  if (IcsInstructionsSlider) {
    const toPercent = (x, y) => (x / y) * 100;
    const setControlBoxPosition = () => {
      const controlBox = document.querySelector('.ics-instructions__slider .ics-instructions__control-box');

      if (window.innerWidth <= 1200) {
        const firstSlideImage = document.querySelector('.ics-instructions__slider .ics-instructions__slide-image');
        const firstSlideImageHeight = firstSlideImage.getBoundingClientRect().height;

        controlBox.style.top = `${firstSlideImageHeight + 20}px`;
      } else {
        controlBox.style.removeProperty('top');
      }
    };

    const initAccordion = (infoItems) => {
      infoItems.forEach((infoItem) => {
        const infoItemsContent = infoItem.querySelector('.info-item-content');

        if (infoItemsContent) {
          infoItem.onclick = () => {
            infoItem.style.pointerEvents = 'none';

            window.innerWidth <= 1200 &&
            slideToggle(infoItemsContent, 500, () => {
              infoItem.style.pointerEvents = 'all';
            });
          };
        }
      });
    };

    const setBorderConnection = (imageRectElementValues, infoRectElementValues, connectingRect) => {
      connectingRect.classList.remove('btr');
      connectingRect.classList.remove('btl');
      connectingRect.classList.remove('bbr');
      connectingRect.classList.remove('bbl');

      if (
        infoRectElementValues.top < imageRectElementValues.top &&
        infoRectElementValues.left < imageRectElementValues.left
      ) {
        connectingRect.classList.add('btr');
      }

      if (
        infoRectElementValues.top < imageRectElementValues.top &&
        infoRectElementValues.left > imageRectElementValues.left
      ) {
        connectingRect.classList.add('btl');
      }

      if (
        infoRectElementValues.top > imageRectElementValues.top &&
        infoRectElementValues.left < imageRectElementValues.left
      ) {
        connectingRect.classList.add('bbr');
      }

      if (
        infoRectElementValues.top > imageRectElementValues.top &&
        infoRectElementValues.left > imageRectElementValues.left
      ) {
        connectingRect.classList.add('bbl');
      }
    };

    const setConnectionStyles = (connections) => {
      connections.forEach((connection) => {
        const { slide, imageRectElement, infoRectElement, connectingRect } = connection;
        const slideInfoValues = slide.getBoundingClientRect();
        const imageRectElementValues = imageRectElement.getBoundingClientRect();
        const infoRectElementValues = infoRectElement.getBoundingClientRect();

        // Calc values
        const closestToTop =
          imageRectElementValues.top >= infoRectElementValues.top
            ? infoRectElementValues.top
            : imageRectElementValues.top;
        const closestToLeft =
          imageRectElementValues.left >= infoRectElementValues.left
            ? infoRectElementValues.left
            : imageRectElementValues.left;

        // Calc position
        const top = toPercent(closestToTop - slideInfoValues.top, slideInfoValues.height);
        const left = toPercent(closestToLeft - slideInfoValues.left, slideInfoValues.width);
        const width = toPercent(
          Math.abs(imageRectElementValues.left - infoRectElementValues.left),
          slideInfoValues.width
        );
        const height = toPercent(
          Math.abs(imageRectElementValues.top - infoRectElementValues.top),
          slideInfoValues.height
        );

        // Styles
        connectingRect.style.top = `${Math.abs(top)}%`;
        connectingRect.style.left = `${Math.abs(left)}%`;
        connectingRect.style.width = `${Math.abs(width)}%`;
        connectingRect.style.height = `${Math.abs(height)}%`;

        setBorderConnection(imageRectElementValues, infoRectElementValues, connectingRect);
      });
    };

    const createConnections = (slides) => {
      const arr = [];

      slides.forEach((slide) => {
        const imageRectElements = slide.querySelectorAll('.ics-instructions__slide-image .element-rect');
        const infoRectElements = slide.querySelectorAll('.ics-instructions__instructions-item .element-rect');

        imageRectElements.forEach((imageRectElement) => {
          const imageRectElementType = imageRectElement.dataset.rect || '';

          infoRectElements.forEach((infoRectElement, index) => {
            const infoRectElementType = infoRectElement.dataset.target || '';

            if (imageRectElementType.toLowerCase() === infoRectElementType.toLowerCase()) {
              const connectingRect = document.createElement('div');
              connectingRect.classList.add('connecting-rect');

              slide.append(connectingRect);

              if (index === 0) {
                infoRectElement.closest('.ics-instructions__instructions-item').classList.add('show');
                connectingRect.classList.add('show');
              }

              arr.push({
                slide,
                imageRectElement,
                infoRectElement,
                connectingRect
              });
            }
          });
        });
      });

      arr.length &&
      arr.forEach((connection) => {
        connection.imageRectElement.onclick = (event) => {
          if (window.innerWidth <= 1200) return;
          event.stopPropagation();
          event.preventDefault();

          arr.forEach((connectionSecond) => {
            connectionSecond.infoRectElement.closest('.ics-instructions__instructions-item').classList.remove('show');
            connectionSecond.connectingRect.classList.remove('show');
            connectionSecond.imageRectElement.classList.remove('active');
          });

          connection.infoRectElement.closest('.ics-instructions__instructions-item').classList.add('show');
          connection.connectingRect.classList.add('show');
          connection.imageRectElement.classList.add('active');
        };
      });

      return arr;
    };

    const setTitle = (instance) => {
      const { activeIndex, slides } = instance;
      const currentSlide = slides[activeIndex];
      const currentSlideTitle = currentSlide.dataset?.title || '';
      const section = currentSlide.closest('.ics-instructions');
      const sectionTitle = section && section.querySelector('.ics-instructions__title');
      const sectionTitleHolder = sectionTitle.dataset?.holder || '';

      if (sectionTitle && currentSlideTitle) {
        sectionTitle.innerHTML = currentSlideTitle;
      } else {
        sectionTitle.innerHTML = sectionTitleHolder;
      }
    };

    let resizeSliderTimerId = null;
    const sliderInit = new Swiper('.ics-instructions__slider .slider-container', {
      pagination: {
        el: '.ics-instructions__pagination',
        type: 'bullets',
        clickable: true
      },
      on: {
        init: (instance) => {
          const sliderEl = instance.$el[0];
          const infoItems = sliderEl.querySelectorAll('.ics-instructions__instructions-item');

          setControlBoxPosition();
          initAccordion(infoItems);
          setTitle(instance);

          instance.connections = createConnections(instance.slides);
          instance.connections?.length && setConnectionStyles(instance.connections);
        },
        resize: (instance) => {
          clearTimeout(resizeSliderTimerId);
          resizeSliderTimerId = setTimeout(() => setControlBoxPosition(instance), 350);
          instance.connections?.length && setConnectionStyles(instance.connections);
        },
        slideChange: (instance) => {
          const { slides, activeIndex } = instance;
          const infoItemsContent = slides[activeIndex].querySelectorAll('.info-item-content');
          setTitle(instance);

          infoItemsContent.length &&
          infoItemsContent.forEach((contentBox) => {
            if (window.getComputedStyle(contentBox).display !== 'none' && window.innerWidth <= 1200) {
              slideUp(contentBox);
            }
          });
        }
      }
    });

    const slideInstructions = document.querySelectorAll('.ics-instructions__slide-instructions');
    // const imageRects = document.querySelectorAll('.ics-instructions__slide-image .element-rect');

    slideInstructions.length &&
    slideInstructions.forEach((slideInstruction) => {
      slideInstruction.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    });

    const zoomBox = document.querySelector('.zoom-box');

    const hideZoomBox = () => {
      zoomBox.classList.remove('active');
    };

    IcsInstructionsSlider.addEventListener('mousemove', (event) => {
      const { target, offsetY, offsetX, pageY, pageX } = event;

      if (target.tagName === 'IMG') {
        zoomBox.classList.add('active');
        const targetScr = target.getAttribute('src');

        const positionX = toPercent(offsetX - (zoomBox.offsetWidth / 4), target.offsetWidth - (zoomBox.offsetWidth / 2));
        const positionY = toPercent(offsetY - (zoomBox.offsetHeight / 4), target.offsetHeight - (zoomBox.offsetHeight / 2));

        zoomBox.style.backgroundImage = `url("${targetScr}")`;
        zoomBox.style.backgroundPosition = `${positionX}% ${positionY}%`;
        zoomBox.style.top = `${pageY + 30}px`;
        zoomBox.style.left = `${pageX + 30}px`;

        target.addEventListener('click', hideZoomBox);
      } else if (target.tagName !== 'IMG') {
        target.removeEventListener('click', hideZoomBox);
        hideZoomBox();
      }
    });

    $('.lightgallery').lightGallery({
      download: false,
      share: false,
      speed: 500,
      loop: false,
      mobileSettings: [
        {
          controls: false,
          showCloseIcon: true,
          download: false
        }
      ]
    });
  }
  // Common end ====================================
  let orderButtons = document.querySelectorAll('[data-set-order-type]');
  orderButtons && orderButtons.forEach(function(item) {
    item.addEventListener('click', function() {
      if ($(this).data('setOrderType')) {
        $('#order-demo input[name="type"]').val($(this).data('setOrderType'));
      }
    });
  });

  const tabs = () => {
    const tabs = document.querySelectorAll('.js-tabs');
    if (!tabs.length) return [];

    // === System variables
    const duration = 500;
    const tabs_int = [];

    // === Methods
    function changeTab() {
      this.isLocked = true;

      fadeOut(this.activeItem.$contentItem, this.duration, () => {
        this.activeItem.$triggerItem.classList.remove('active');
        this.activeItem.$contentItem.classList.remove('active');
        this.activeItem = this.items[this.activeIndex];

        this.activeItem.$triggerItem.classList.add('active');
        fadeIn(this.activeItem.$contentItem, this.duration, () => {
          this.activeItem.$contentItem.classList.add('active');
          this.isLocked = false;
        });
      });
    }

    function setActiveTab(index) {
      if (this.isLocked) return;

      if (typeof index !== 'number') {
        return console.warn(`To change tab index should be a number, now is ${index}`);
      }

      if (this.items.length < index + 1 || index < 0) {
        return console.warn(`No one tab item with index ${index}, current items length is ${this.items.length} is`, this);
      }

      this.activeIndex = index;
      this.changeTab();
    }

    const createTabItems = (tabs) => {
      const triggers = tabs.$tabs.querySelectorAll('.js-tab-btn');
      if (!triggers.length) {
        return console.warn(`No one tab item found in`, tabs);
      }

      const items = [];

      triggers.forEach(($btn) => {
        const targetID = $btn.dataset?.tabTarget || '';
        const $targetItem = tabs.$tabs.querySelector(`#${targetID}`);

        items.push({
          $triggerItem: $btn,
          $contentItem: $targetItem,
          isActive: false
        });
      });

      return items.length ? items : [];
    };

    const init = ($tabs) => {
      const tabs = {};

      tabs.$tabs = $tabs;
      tabs.isLocked = false;
      tabs.activeIndex = +tabs.$tabs.dataset?.activeIndex || 0;
      tabs.duration = (+tabs.$tabs.dataset?.duration || duration) / 2;
      tabs.items = createTabItems(tabs);

      tabs.activeItem = tabs.items[tabs.activeIndex];

      tabs.setActiveTab = setActiveTab.bind(tabs);
      tabs.changeTab = changeTab.bind(tabs);

      tabs.setActiveTab(tabs.activeIndex);

      tabs.items.forEach((item, index) => {
        item.$triggerItem.addEventListener('click', () => {
          tabs.setActiveTab(index);
        });
      });

      tabs_int.push(tabs);
    };

    // Init
    tabs.forEach(init);

    return tabs_int;
  };

  tabs();

  videoModal();
});
