// Create element
const createEl = el => document.createElement(el);

// Calc scrollbar width
const calcScroll = () => {
  let div = document.createElement('div');
  div.style.visibility = 'hidden';
  div.style.width = '50px';
  div.style.height = '50px';
  div.style.overflowY = 'scroll';
  document.body.appendChild(div);
  let scrollWidth = div.offsetWidth - div.clientWidth;
  div.remove();
  return scrollWidth;
}
