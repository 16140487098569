const addInvalidClass = (el, bool) => {
  if (bool) {
    el.classList.remove('invalid');
    el.classList.add('valid');
  } else {
    el.classList.remove('valid');
    el.classList.add('invalid');
  }
};

const mailValidate = (input) => {
  const mailValid = /\S+@\S+\.\S+/.test(input.value);
  addInvalidClass(input.closest('[data-invalid-target]'), mailValid);
  return mailValid;
};

const phoneValidate = (input) => {
  const { maxLength, value, minLength } = input;
  let telValid = value.length <= +maxLength && value.length >= +minLength;
  addInvalidClass(input.closest('[data-invalid-target]'), telValid);

  return telValid;
};

const emptyInputValidate = (input) => {
  const inputValid = input.value !== '';
  addInvalidClass(input.closest('[data-invalid-target]'), inputValid);
  return inputValid;
};

const checkboxValidate = (input) => {
  const { checked } = input;
  addInvalidClass(input.closest('[data-invalid-target]'), checked);
  return checked;
};

const formHandler = (input) => {
  if (input.dataset.needValidation && !JSON.parse(input.dataset.needValidation.toLowerCase())) return;

  switch (input.dataset.validationType) {
    case 'email':
      return mailValidate(input);
    case 'phone':
      return phoneValidate(input);
    case 'empty':
      return emptyInputValidate(input);
    case 'checkbox':
      return checkboxValidate(input);
  }
};
