const addProviderInfo = (modal, button) => {
  const providerInput = modal.querySelector('.js-provider-input');
  const providerSelect = modal.querySelector('.js-provider-tariff');
  const providerName = button.dataset?.provider
  const data = button.dataset?.json && JSON.parse(button.dataset.json)

  if(!data) {
    console.warn('Data in button not found', button);
  } if(!providerSelect) {
    console.warn('Provider tariff field is not found in', modal);
  } else if(providerSelect && data) {
    const dataKeys = Object.keys(data)
    const dataValues = Object.values(data)

    const options = dataKeys.map((key, index) => {
      return `<option value='${key}'>${dataValues[index]}</option>`
    })

    providerSelect.innerHTML = options.join('')
  }

  if (!providerInput) {
    console.warn('Provider name field is not found in', modal);
  } else if(!providerName || providerName === '') {
    console.warn('"data-provider" on trigger is empty or not found in', button);
  } else if(providerInput && providerName) {
    providerInput.value = providerName;
  }
};

const removeProviderInfo = (modal) => {
  modal.classList.remove('show-provider-select');
  const providerInput = modal.querySelector('.js-provider-input');

  if(providerInput) {
    providerInput.value = '';
  }
};

class Modal {
  constructor(options = {}) {
    if (options.modalSelector && options.triggerSelector) {
      this.modal = document.querySelectorAll(options.modalSelector) || null;
      this.trigger = document.querySelectorAll(options.triggerSelector) || null;
      this.closeBtn = options.closeSelector || null;
      this.modalActiveClass = options.modalActiveClass || 'show';
      this.disableScrollbar = options.disableScrollbar || true;
      this.closeOnLightbox = options.closeOnLightbox || true;
      this.closeOnEsc = options.closeOnEsc || true;
      this.scrollbarWidht = this.calcScroll();
      this.bodyChangedWidth = false;
      this.on = {
        init: options.on && options.on.init ? options.on.init.bind(this.on) : null,
        modalOpen: options.on && options.on.modalOpen ? options.on.modalOpen.bind(this.on) : null,
        modalClose: options.on && options.on.modalClose ? options.on.modalClose.bind(this.on) : null
      } || {};
      this.init();
    }
  }

  init = () => {
    this.on.init && this.on.init(this);
    this.trigger.forEach(btn => btn.addEventListener('click', this.showModal));
    this.modal.forEach(modal => {
      const modalName = modal.id.replace(/-/g, '').toLowerCase();
      modal.onInit = this.on.init;
      modal.onOpen = this.on.modalOpen;
      modal.onClose = this.on.modalClose;
      window[modalName] = modal;
      window[modalName] = modal;

      if (this.closeBtn) {
        modal
          .querySelectorAll(this.closeBtn)
          .forEach(btn => btn.onclick = () => this.closeModal(modal));
      }
      if (this.closeOnLightbox) modal.onclick = e => {
        if (e.target === modal) this.closeModal(modal);
      };
    });
  };

  showModal = e => {
    this.modal.forEach(modal => {
      const additionalClassName = e.currentTarget.dataset.additionalClassName;

      if (modal.id.toLowerCase() === e.currentTarget.dataset.modalTarget.toLowerCase()) {

        modal.classList.add(this.modalActiveClass);
        additionalClassName && modal.classList.add(additionalClassName);

        if(e.currentTarget.dataset?.modalTarget?.toLowerCase() === 'order-demo') {
          addProviderInfo(modal, e.currentTarget);
        }

        this.disableScroll();
        this.on.modalOpen && this.on.modalOpen(this, modal, e.currentTarget);
        if (this.closeOnEsc) window.onkeydown = e => {
          if (e.code === 'Escape') this.closeModal(modal, e.currentTarget, additionalClassName);
        };
      } else {
        if (modal.classList.contains(this.modalActiveClass)) {
          this.closeModal(modal, e.currentTarget, additionalClassName);
        }
      }
    });
  };

  closeModal = (modal, trigger) => {
    modal.classList.remove(this.modalActiveClass);
    modal.ontransitionend = () => {
      removeProviderInfo(modal);
      modal.ontransitionend = null;
    };
    this.enableScroll();
    this.on.modalClose && this.on.modalClose(this, modal, trigger);
  };

  disableScroll() {
    const { body } = document;
    if (Math.round(body.getBoundingClientRect().height) > window.innerHeight && this.disableScrollbar) {
      body.style.overflow = 'hidden';
      body.style.paddingRight = `${this.scrollbarWidht}px`;
      this.bodyChangedWidth = true;
    }
  }

  enableScroll() {
    const { body } = document;

    if (this.bodyChangedWidth && this.disableScrollbar) {
      body.style.overflow = 'initial';
      body.style.paddingRight = '0px';
      this.bodyChangedWidth = false;
    }
  }

  calcScroll() {
    let div = document.createElement('div');
    div.style.visibility = 'hidden';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.overflowY = 'scroll';
    document.body.appendChild(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();
    return scrollWidth;
  }
}
